#root {
  max-width: 1188px;
  margin: auto;
}

a{
  text-decoration: none;
}

@media screen and (max-width: 1280px) {
  #root {
    max-width: 948px;
  }
}

@media screen and (max-width: 770px) {
  #root {
    max-width: 500px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #191d26;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes float {
  0% {
    transform: translatey(10px);
  }
  50% {
    transform: translatey(-5px);
  }
  100% {
    transform: translatey(10px);
  }
}

@keyframes scalling {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
